const importAll = (requireContext) => {
  const folders = {};
  requireContext.keys().forEach((filePath) => {
    const pathParts = filePath.replace("./", "").split("/");
    const folderName = pathParts[0];
    const fileName = pathParts[pathParts.length - 1];
    const thumbnailPath = `./thumbnails/${folderName}/${fileName}`;

    if (!folders[folderName]) {
      folders[folderName] = [];
    }

    try {
      // Check if thumbnail exists
      folders[folderName].push(require(thumbnailPath));
    } catch (error) {
      // Fallback to original image if no thumbnail is found
      folders[folderName].push(requireContext(filePath));
    }
  });
  return folders;
};

const folderData = importAll(require.context("./Media", true, /\.(png|jpg|jpeg|gif)$/));

export default folderData;
