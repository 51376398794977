import React, { useState } from "react";
import { Card, Row, Col, Typography, Modal, Button } from "antd";
import folderData from "./folderdata";
import thumbnailData from "./thumbnails";
import "antd/dist/reset.css";

const { Title } = Typography;

const App = () => {
  const [currentFolder, setCurrentFolder] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFolderClick = (folderName) => setCurrentFolder(folderName);
  const handleBackClick = () => setCurrentFolder(null);
  const handleImageClick = (src) => setSelectedImage(src);
  const handleModalClose = () => setSelectedImage(null);

  const applyWatermark = (src, callback) => {
    const img = new Image();
    img.src = src;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      ctx.font = `${canvas.width / 25}px Arial`;
      ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
      ctx.textAlign = "center";
      ctx.fillText("3DPrintedCat", canvas.width / 2, canvas.height - 30);
      callback(canvas);
    };
  };

  const downloadImageWithWatermark = (src) => {
    applyWatermark(src, (canvas) => {
      const link = document.createElement("a");
      link.download = "3DPrintedCat_Image.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  const ThumbnailImage = ({ src, onClick, fixedHeight }) => (
    <img
      src={src}
      alt="Thumbnail"
      style={{
        width: "100%",
        height: fixedHeight ? "200px" : "auto",
        objectFit: fixedHeight ? "cover" : "contain",
        borderRadius: "16px",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );

  const WatermarkedImage = ({ src }) => {
    const canvasRef = React.useRef(null);

    React.useEffect(() => {
      const canvas = canvasRef.current;
      if (canvas) {
        applyWatermark(src, (generatedCanvas) => {
          const ctx = canvas.getContext("2d");
          canvas.width = generatedCanvas.width;
          canvas.height = generatedCanvas.height;
          ctx.drawImage(generatedCanvas, 0, 0);
        });
      }
    }, [src]);

    return (
      <div onClick={() => handleImageClick(src)} style={{ cursor: "pointer" }}>
        <canvas ref={canvasRef} style={{ width: "100%", borderRadius: "16px" }}></canvas>
      </div>
    );
  };

  return (
    <div style={{ padding: 40, background: "#f7f9fc", minHeight: "100vh", color: "#000" }}>
      {!currentFolder ? (
        <>
          <Title level={1} style={{ textAlign: "center", marginBottom: 40 }}>3DPrintedCat/Brandon Paul</Title>
          <Row gutter={[24, 24]} justify="center">
            {Object.keys(thumbnailData).map((folder) => (
              <Col key={folder} xs={24} sm={12} md={8} lg={6}>
                <Card
                  hoverable
                  style={{
                    borderRadius: "16px",
                    overflow: "hidden",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
                  }}
                  onClick={() => handleFolderClick(folder)}
                >
                  <ThumbnailImage src={thumbnailData[folder][0]} fixedHeight />
                  <h3 style={{ textAlign: "center", margin: 0, fontWeight: "bold" }}>{folder}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <>
          <Button onClick={handleBackClick} style={{ display: "block", margin: "20px auto" }}>Back</Button>
          <Title level={2} style={{ textAlign: "center", marginBottom: 40 }}>{currentFolder} Gallery</Title>
          <Row gutter={[24, 24]} justify="center">
            {thumbnailData[currentFolder].map((thumbnail, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <ThumbnailImage
                  src={thumbnail}
                  onClick={() => handleImageClick(folderData[currentFolder][index])}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
      <Modal visible={!!selectedImage} footer={null} onCancel={handleModalClose} centered>
        {selectedImage && (
          <>
            <div style={{ textAlign: "center" }}>
              <canvas
                ref={(canvas) => {
                  if (canvas) {
                    applyWatermark(selectedImage, (generatedCanvas) => {
                      const ctx = canvas.getContext("2d");
                      canvas.width = generatedCanvas.width;
                      canvas.height = generatedCanvas.height;
                      ctx.drawImage(generatedCanvas, 0, 0);
                    });
                  }
                }}
                style={{ maxWidth: "100%", maxHeight: "70vh", borderRadius: "16px" }}
              ></canvas>
            </div>
            <Button style={{ marginTop: 20 }} type="primary" onClick={() => downloadImageWithWatermark(selectedImage)}>
              Download Image
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default App;
