const importThumbnails = (requireContext) => {
    const folders = {};
    requireContext.keys().forEach((filePath) => {
      const pathParts = filePath.replace("./", "").split("/");
      const folderName = pathParts[0];
  
      if (!folders[folderName]) {
        folders[folderName] = [];
      }
  
      // Import only thumbnails
      folders[folderName].push(requireContext(filePath));
    });
    return folders;
  };
  
  // Import only thumbnails from the 'thumbnails' folder
  const thumbnailData = importThumbnails(require.context("./thumbnails", true, /\.(png|jpg|jpeg|gif)$/));
  
  export default thumbnailData;
  